
import { defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmChip from '@/components/shared/TmChip.vue'
import useWorkspace from '@/compositions/admin/useWorkspace'

export default defineComponent({
  name: 'RolesDetailsTop',
  components: {
    TmDropdownItem,
    DetailsHero,
    TmButton,
    TmChip,
    TmDropdown,
  },
  props: {
    system: Boolean,
  },
  setup(props) {
    const { openEditRoleModal, openDuplicateRoleModal, openRolesDeleteModal } = useWorkspace()

    const role = ref({
      title: props.system ? 'Agent' : 'Supervisor',
      description: 'Edit role permissions for',
      routeName: 'base.admin.users',
      totalUsers: 33,
    })

    return {
      role,
      openRolesDeleteModal,
      openEditRoleModal,
      openDuplicateRoleModal,
    }
  },
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64e2236e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "role-expansion-content" }
const _hoisted_2 = { class: "role-expansion-content__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settings, (field) => {
        return (_openBlock(), _createElementBlock("li", {
          key: field.id,
          class: "d-block"
        }, [
          _createVNode(_component_tm_field, {
            class: "lh-20",
            type: field.fieldType,
            disable: _ctx.disabled || _ctx.system,
            label: field.label,
            "model-value": field.value,
            "onUpdate:modelValue": ($event: any) => (field.value = $event)
          }, null, 8, ["type", "disable", "label", "model-value", "onUpdate:modelValue"])
        ]))
      }), 128))
    ])
  ]))
}

import { defineComponent } from 'vue'

type CheckboxObj = {
  id: string;
  fieldType: string;
  label: string;
  value: boolean;
}
type CheckboxGroupObj = {
  id: string;
  fieldType: string;
  label: string;
  value: boolean;
  items: CheckboxObj[];
}

export default defineComponent({
  name: 'RoleExpansionContent',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Array,
      default: () => [],
    },
    system: {
      type: Boolean,
    },
  },
  setup() {
    const isAllEnabled = (items: CheckboxGroupObj[]) => {
      const enabledItems = items.filter((item: CheckboxObj) => item.value)
      if (enabledItems.length === items.length) {
        return true
      }
      if (enabledItems.length === 0) {
        return false
      }
      return null
    }

    const toggleAll = (items: CheckboxGroupObj[]) => {
      const newVal = isAllEnabled(items)
      items.forEach((item) => {
        item.value = !newVal
      })
    }

    return {
      isAllEnabled,
      toggleAll,
    }
  },
})


import { defineComponent } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import RolePermissions from '@/components/pages/admin/workspace/roles/roleDetails/RolePermissions.vue'
import RoleDetailsTop from '@/components/pages/admin/workspace/roles/roleDetails/RoleDetailsTop.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'WorkspaceRoleDetails',
  components: {
    PageBlock,
    TmAlert,
    RolePermissions,
    RoleDetailsTop,
  },
  setup() {
    const route = useRoute()
    const enableSystem = !!route.query.system
    const breadcrumbs = [
      { label: 'Roles', name: 'base.admin.roles' },
      { label: enableSystem ? 'Agent' : 'Supervisor', url: '' },
    ]

    return {
      enableSystem,
      breadcrumbs,
    }
  },
})


import { defineComponent, computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import RoleExpansionContent from '@/components/pages/admin/workspace/roles/roleDetails/RoleExpansionContent.vue'
import { useBottomBar } from '@/compositions/bottomBar'

type SectionObj = {
  label: string;
  caption: string;
  opened: boolean;
  switchableState: boolean;
  activated: boolean;
  settings: any;
}

export default defineComponent({
  name: 'RolePermissions',
  components: {
    TmButton,
    TmSearch,
    TmExpansion,
    InfoCard,
    RoleExpansionContent,
  },
  props: {
    system: {
      type: Boolean,
    },
  },
  setup(props) {
    const { provideData, getDiscardFunc } = useBottomBar()

    const search = ref('')
    const sections = ref<SectionObj[]>([
      {
        label: 'Actions',
        caption: 'Manage the global actions applicable to the whole workspace.',
        opened: true,
        switchableState: false,
        activated: true,
        settings: [
          { id: '1', fieldType: 'checkbox', label: 'Export data', value: true },
          { id: '2', fieldType: 'checkbox', label: 'Delete data', value: true },
          { id: '3', fieldType: 'checkbox', label: 'Make outbound calls', value: true },
          { id: '6', fieldType: 'checkbox', label: 'Import data', value: false },
        ],
      },
      {
        label: 'Messaging',
        caption: 'Manage the permissions related to messaging.',
        opened: false,
        switchableState: true,
        activated: true,
        settings: [
          { id: '1', fieldType: 'checkbox', label: 'SMS chat', value: true },
          { id: '2', fieldType: 'checkbox', label: 'Scheduled', value: true },
          { id: '3', fieldType: 'checkbox', label: 'History', value: true },
          { id: '4', fieldType: 'checkbox', label: 'Compose page', value: true },
          { id: '5', fieldType: 'checkbox', label: 'MMS', value: true },
        ],
      },
      {
        label: 'Contacts',
        caption: 'Manage the permissions related to contacts, lists and importing.',
        opened: false,
        switchableState: true,
        activated: true,
        settings: [
          { id: '1', fieldType: 'checkbox', label: 'Contacts', value: true },
          { id: '2', fieldType: 'checkbox', label: 'Resubscribe', value: true },
          { id: '3', fieldType: 'checkbox', label: 'Create new lists', value: true },
        ],
      },
      {
        label: 'Services',
        caption: 'Manage the permissions related to additional services.',
        opened: false,
        switchableState: true,
        activated: true,
        settings: [
          { id: '1', fieldType: 'checkbox', label: 'Email to sms', value: true },
          { id: '2', fieldType: 'checkbox', label: 'Distribution lists', value: true },
          { id: '3', fieldType: 'checkbox', label: 'Text-to-speech settings', value: true },
          { id: '4', fieldType: 'checkbox', label: 'API', value: true },
          { id: '5', fieldType: 'checkbox', label: 'Automation rules', value: true },
          { id: '6', fieldType: 'checkbox', label: 'Carrier lookup', value: true },
          { id: '7', fieldType: 'checkbox', label: 'Subscribe forms', value: true },
          { id: '8', fieldType: 'checkbox', label: 'Sender settings', value: true },
          { id: '9', fieldType: 'checkbox', label: 'Calls', value: true },
          { id: '10', fieldType: 'checkbox', label: 'Email lookup', value: true },
          { id: '11', fieldType: 'checkbox', label: 'Verify api', value: true },
        ],
      },
      {
        label: 'Account',
        caption: 'Manage the permissions related to the account.',
        opened: false,
        switchableState: true,
        activated: true,
        settings: [
          { id: '1', fieldType: 'checkbox', label: 'Account settings', value: true },
          { id: '2', fieldType: 'checkbox', label: 'Messaging data', value: true },
          { id: '3', fieldType: 'checkbox', label: 'Notifications', value: true },
        ],
      },
      {
        label: 'Reporting',
        caption: 'Manage the permissions related to the reporting section.',
        opened: false,
        switchableState: true,
        activated: true,
        settings: [
          { id: '1', fieldType: 'checkbox', label: 'Reporting overview', value: true },
          { id: '2', fieldType: 'checkbox', label: 'Messages', value: true },
          { id: '3', fieldType: 'checkbox', label: 'Calls', value: true },
          { id: '4', fieldType: 'checkbox', label: 'Statements', value: true },
          { id: '5', fieldType: 'checkbox', label: 'Audit logs', value: true },
          { id: '6', fieldType: 'checkbox', label: 'Sub-accounts', value: true },
          { id: '7', fieldType: 'checkbox', label: 'Numbers reporting', value: true },
        ],
      },
    ])
    const defaultSections = sections.value

    const isAllCollapsed = computed(() => {
      for (let i = 0; i < sections.value.length; i++) {
        if (sections.value[i].opened) {
          return false
        }
      }
      return true
    })

    const toggleSections = () => {
      const value = isAllCollapsed.value
      sections.value.forEach((section) => {
        section.opened = value
      })
    }

    const restoreSections = () => {
      sections.value = defaultSections
    }

    watch(sections.value, () => {
      if (!props.system) {
        provideData(1)
      }
    })

    onMounted(() => {
      provideData(0, restoreSections)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      search,
      sections,
      isAllCollapsed,
      toggleSections,
    }
  },
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-789f41ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-space-between pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_search = _resolveComponent("tm-search")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_role_expansion_content = _resolveComponent("role-expansion-content")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!
  const _component_info_card = _resolveComponent("info-card")!

  return (_openBlock(), _createBlock(_component_info_card, {
    title: "Permissions",
    class: "role-permissions",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_search, {
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          size: "small",
          placeholder: "Search all permissions",
          class: "w240px"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_tm_button, { onClick: _ctx.toggleSections }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              left: "",
              name: _ctx.isAllCollapsed ? 'unfold_more' : 'unfold_less'
            }, null, 8, ["name"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.isAllCollapsed ? 'Expand all' : 'Collapse all'), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
        return (_openBlock(), _createBlock(_component_tm_expansion, {
          key: section.label,
          modelValue: section.opened,
          "onUpdate:modelValue": ($event: any) => ((section.opened) = $event),
          label: section.label,
          caption: section.caption,
          class: _normalizeClass(["role-permissions__expansion", {'role-permissions__expansion--disabled': !section.activated || _ctx.system }])
        }, _createSlots({
          default: _withCtx(() => [
            _createVNode(_component_role_expansion_content, {
              system: _ctx.system,
              settings: section.settings,
              disabled: !section.activated,
              class: _normalizeClass({'role-permissions__settings--disabled': !section.activated})
            }, null, 8, ["system", "settings", "disabled", "class"])
          ]),
          _: 2
        }, [
          (section.switchableState)
            ? {
                name: "tm-expansion-btn",
                fn: _withCtx(() => [
                  _createVNode(_component_tm_field, {
                    "model-value": section.activated,
                    disable: _ctx.system,
                    type: "toggle",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                    "onUpdate:modelValue": ($event: any) => (section.activated = $event)
                  }, null, 8, ["model-value", "disable", "onUpdate:modelValue"])
                ])
              }
            : undefined
        ]), 1032, ["modelValue", "onUpdate:modelValue", "label", "caption", "class"]))
      }), 128))
    ]),
    _: 1
  }))
}